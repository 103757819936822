<template>
	<!-- 静力水准仪 2 -->
	<div class="staticLevel-box">
		<div class="table-box" v-show="boxType == 1">
			<ul class="header-ul">
				<li class="li-w-s text-overflow">序号</li>
				<li class="li-w-l text-overflow">时间</li>
				<li class="li-w-l text-overflow">当前变化量</li>
				<li class="li-w-l text-overflow">累计变化量</li>
			</ul>
			<div class="scroll-box">
				<vue-scroll>
					<div class="content-ul-box">
						<ul class="content-ul" v-for="(i, index) in list" :key="index">
							<li class="li-w-s text-overflow">{{ index + 1 }}</li>
							<li class="li-w-l text-overflow">{{ i.time }}</li>
							<li class="li-w-l text-overflow">{{ i.v1 }}mm</li>
							<li class="li-w-l text-overflow">{{ i.v2 }}mm</li>
						</ul>
					</div>
				</vue-scroll>
			</div>
		</div>
		<div class="graph-box" v-show="boxType == 2">
			<div class="echarts-box" id="staticLevelGraph"></div>
			<div class="echarts-box" id="staticLevelGraph1"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'staticLevel',
	data() {
		return {
			list: [], //列表数据
			boxType: 1,
			myChart: null, //echarts的DOM对象
			myChart1: null, //echarts的DOM对象
			option: {
				title: {
					text: '折线图堆叠'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: ['Mon']
				},
				grid: {
					left: '2%',
					top: 60,
					bottom: 60,
					right: '2%'
				},
				xAxis: {
					type: 'category',
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: 'Mon',
						type: 'line',
						stack: '总量',
						data: [150, 230, 224, 218, 135, 147, 260]
					}
				]
			} //折线图配置参数
		};
	},
	created() {},
	mounted() {
		var chartDom = document.getElementById('staticLevelGraph');
		this.myChart = this.echarts.init(chartDom);
		var chartDom1 = document.getElementById('staticLevelGraph1');
		this.myChart1 = this.echarts.init(chartDom1);
	},
	computed: {},
	methods: {
		// 获取数据
		getList(data) {
			this.list = data;
		},
		// 曲线图绘制
		drawGraph() {
			var option = this.deepCopy(this.option);
			option && this.myChart.setOption(option);
			this.myChart.resize();
			var option1 = this.deepCopy(this.option);
			option1 && this.myChart1.setOption(option1);
			this.myChart1.resize();
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.staticLevel-box {
	width: 100%;
	height: 100%;
	background-color: #ffffff;

	// 数据表
	.table-box {
		width: 100%;
		height: 100%;

		ul {
			// width: 798px;
			width: -webkit-fit-content;
			margin: 0 15px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.text-center {
				text-align: center;
			}

			li {
				text-align: center;
				flex-shrink: 0;
				padding: 10px 15px;
			}

			.li-w-s {
				width: 50px;
			}

			.li-w-l {
				width: 208px;
			}
		}

		.header-ul {
			margin: 0 15px;

			li {
				font-size: 15px;
				font-weight: 600;
				border: thin solid #c6c9cf;
				border-left: none;
			}

			li:first-child {
				border-left: thin solid #c6c9cf;
			}
		}

		.scroll-box {
			width: 100%;
			height: calc(100% - 52px);

			.content-ul-box {
				.content-ul {
					margin: 0 15px;

					li {
						font-size: 14px;
						border-bottom: thin solid #c6c9cf;
						border-right: thin solid #c6c9cf;
					}

					li:first-child {
						border-left: thin solid #c6c9cf;
					}
				}

				.content-ul:hover {
					background-color: #ebeef5;
				}
			}
		}
	}

	// 折线图
	.graph-box {
		width: 100%;
		height: 100%;

		.echarts-box {
			width: 100%;
			height: 400px;

			&div {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
