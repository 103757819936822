export default () => {
	return {
		list: [], // 列表数据
		instrumentOptions: [{
				name: '静力水准仪',
				id: 1
			},
			{
				name: '倾角计',
				id: 2
			},
			{
				name: '裂缝计',
				id: 3
			},
			{
				name: '激光测距仪',
				id: 4
			},
		], // 仪器选择列表
		instrument: null, // 选择的仪器
		pageType: null, // 展示页面类型
		pointGroupData: {}, // 测点选择数据
		// pointGroupOptions:[],// 点组选择列表
		pointGroup: '', // 选择的测点
		dataType: 1, //数据展示类型
		times: [], //时间

		queryParameter: {
			projectId: 0,
			begin: '',
			end: '',
			type: '',
			pointName: ''
		}, //接口请求参数
		parameter: {}, // 搜索传递过来的数据
	}
}
