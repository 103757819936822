<template>
	<!-- 变形数据 -->
	<div class="deformationData-page">
		<div class="content-box">
			<!-- 静力水平仪 -->
			<staticLevel ref="staticLevel" v-show="pageType == 1"></staticLevel>
			<!-- 倾角计 -->
			<inclinometer ref="inclinometer" v-show="pageType == 2"></inclinometer>
			<!-- 裂缝计 -->
			<crack-meter ref="crackMeter" v-show="pageType == 3"></crack-meter>
			<!-- 激光测距仪 -->
			<laser-range-finder ref="laserRangeFinder" v-show="pageType == 4"></laser-range-finder>
		</div>
	</div>
</template>

<script>
import deformation from './deformation.js';
export default deformation;
</script>

<style scoped lang="scss">
@import './deformation.scss';
</style>
