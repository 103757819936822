<template>
	<div class="chart-box">
		<div class="echarts-box"><div id="graph"></div></div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			time: [],
			that: null,
			list: {},
			myChart: null,
			alert: 0, //图表标记线
			pointName: '' //选择的第一个测点名称
		};
	},
	created() {
		this.that = this;
		this.alert = 0;
	},
	mounted() {
		// this.drawChart();
	},
	computed: {},
	methods: {
		// 获取数据
		getChartData(value) {
			// console.log(value);
			var axiosArr = [];
			var name = [];
			value.forEach((i, index) => {
				let obj = {};
				for (let k in i) {
					if (k != 'name') {
						obj[k] = i[k];
					} else {
						name.push(i[k]);
					}
				}
				if (index == 0) {
					this.pointName = i.name;
				}
				axiosArr.push(this.axios.post('web2/data/odnr/gcdata', obj));
			});
			this.axios.all(axiosArr).then(res => {
				this.list = {};
				// // console.log(res)
				res.forEach((item, index) => {
					if (item.status) {
						if (index == 0) {
							this.alert = item.data.alert;
						}
						this.list[name[index]] = item.data.array;
					}
				});
				if (JSON.stringify(this.list) != '{}') {
					this.drawChart();
				} else {
					this.$message({
						message: '暂无数据',
						type: 'warning'
					});
					// if(this.myChart != null){
					// 	this.myChart.dispose();
					// }
				}
			});
		},
		// 绘制
		drawChart() {
			if (!this.myChart) {
				var chartDom = document.getElementById('graph');
				var myChart = this.echarts.init(chartDom);
				this.myChart = myChart;
			}

			var option;

			option = {
				grid: {
					left: '100',
					right: '260',
					bottom: '80'
				},
				legend: {
					type: 'scroll',
					orient: 'vertical',
					right: 0,
					top: 20,
					bottom: 20,
					textStyle: {
						width: 160,
						overflow: 'breakAll'
					}
				},
				dataZoom: [
					{
						show: true,
						realtime: true,
						start: 0,
						end: 100
					},
					{
						type: 'inside',
						realtime: true,
						start: 0,
						end: 100
					}
				],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						animation: false,
						label: {
							backgroundColor: '#505765'
						}
					}
				},
				color: [
					'#5470c6',
					'#91cc75',
					'#fac858',
					'#73c0de',
					'#9a60b4',
					'#3ba272',
					'#fc8452',
					'#ea7ccc',
					'#8cfaff',
					'#d8ffbe',
					'#f3ffa4',
					'#ffcd86',
					'#0000ff',
					'#096d38',
					'#009c8f',
					'#b300b3',
					'#a16b00',
					'#53007d',
					'#c84264',
					'#b5b55a'
				],
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: [
					{
						// name: '三向合成幅值',
						nameLocation: 'end',
						nameTextStyle: {
							fontSize: 15
						},
						type: 'value',

						splitLine: {
							show: false
						},
						axisLine: {
							show: true
						},
						axisLabel: {
							show: true,
							margin: 35, //刻度标签与轴线之间的距离。
							formatter: val => {
								return val.toFixed(2);
							}
						},
						axisTick: {
							show: true
						},
						max: this.alert
					},
					{
						// name: '三向最大值主频',
						nameLocation: 'end',
						nameTextStyle: {
							fontSize: 15
						},
						alignTicks: true,
						type: 'value',
						splitLine: {
							show: false
						},
						axisLine: {
							show: true
						},
						axisTick: {
							show: true
						}
					}
				],
				series: [
					{
						name: '幅值（cm/s）',
						data: [],
						type: 'line',
						smooth: true,
						lineStyle: {
							width: 1
						},
						markLine: {
							data: [
								{
									type: 'average',
									yAxis: this.alert
								}
							],
							lineStyle: {
								color: '#ff0000'
							},
							label: {
								position: 'start',
								// color: '#ff0000',
								textStyle: {
									color: '#ff0000', //标注线终点文字颜色
									fontSize: 14,
									fontWeight: 500
									// padding: [0, 0, 10, 0] //文字间距
								},
								formatter: val => {
									return val.value.toFixed(1);
								}
							},
							silent: true
						}
					},
					{
						name: '主频（Hz）',
						data: [],
						type: 'line',
						smooth: true,
						lineStyle: {
							width: 1
						},
						yAxisIndex: 1
					}
				]
			};
			var time = [];
			if (JSON.stringify(this.list) != '{}') {
				option.series = [];
			}
			for (let k in this.list) {
				let obj1 = {
					name: '幅值（cm/s）',
					data: [],
					type: 'line',
					smooth: true,
					lineStyle: {
						width: 1
					}
				};
				let obj2 = {
					name: '主频（Hz）',
					data: [],
					type: 'line',
					smooth: true,
					lineStyle: {
						width: 1
					},
					yAxisIndex: 1
				};
				if (k == this.pointName) {
					obj1.markLine = {
						data: [
							{
								type: 'average',
								yAxis: this.alert
							}
						],
						lineStyle: {
							color: '#ff0000'
						},
						label: {
							position: 'start',
							// color: '#ff0000',
							textStyle: {
								color: '#ff0000', //标注线终点文字颜色
								fontSize: 14,
								fontWeight: 500
								// padding: [0, 0, 10, 0] //文字间距
							},
							formatter: val => {
								return val.value.toFixed(1);
							}
						},
						silent: true
					};
				}
				obj1.name = k + obj1.name;
				obj2.name = k + obj2.name;
				this.list[k].forEach((i, index) => {
					obj1.data.push(i.value);
					obj2.data.push(i.frq);
					if (k == this.pointName) {
						time.push(i.time);
					}
				});

				option.series.push(obj1);
				option.series.push(obj2);
			}

			option.xAxis.data = time.map(function(str) {
				return str.replace(' ', '\n');
			});
			option && this.myChart.setOption(option, true);
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.chart-box {
	width: 100%;
	height: 100%;

	header {
		width: calc(100% - 20px);
		padding: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.echarts-box {
		width: 100%;
		// height: calc(100% - 70px);
		height: 100%;

		#graph {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
